import { action, makeAutoObservable, runInAction } from 'mobx';
import { InvoiceModel } from '../assets/models/invoices/invoice.model';
import { OptionalTimePeriodRequest } from '../assets/requests/TimePeriod.request';
import { getAgencyInvoices, getInvoiceBeneficiariesByDate } from '../Services/invoicesAPI.service';
import { PaymentMethodType } from '@assets/types/PaymentMethod.type';
import { Timestamp } from 'firebase/firestore';

export interface OrderInvoices {
  orderUid: string;
  agencyUid: string;
  creationDate: Timestamp;
  orderPeriod: Timestamp;
  orderReference: string;
  paymentMethod: PaymentMethodType;
  mainInvoice: InvoiceModel;
  feesInvoice?: InvoiceModel;
}

export class AgencyInvoiceStore {
  invoicesLoadedStatus: string = 'pending';
  isInvoicesLoading: boolean = false;
  invoices: InvoiceModel[] = [];
  ordersInvoices: OrderInvoices[] = [];
  selectedOrder: OrderInvoices;

  isInvoiceBeneficiariesLoading: boolean = false;
  invoiceBeneficiariesLoadedStatus: string = 'pending';
  invoiceBeneficiariesByDateList = [];

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action reset() {
    this.invoices = [];
    this.invoiceBeneficiariesLoadedStatus = 'pending';
    this.invoicesLoadedStatus = 'pending';
  }

  @action setOrdersInvoices(value: OrderInvoices[]) {
    this.ordersInvoices = value;
  }

  @action setInvoices(value: InvoiceModel[]) {
    this.invoices = value;
  }

  @action setSelectedOrder(value: OrderInvoices) {
    this.selectedOrder = value;
  }

  @action setInvoicesLoading(value: boolean) {
    this.isInvoicesLoading = value;
  }

  @action setInvoiceBeneficiariesLoading(value: boolean) {
    this.isInvoiceBeneficiariesLoading = value;
  }

  sortInvoicesByRechargingMonthAndYear(invoices: InvoiceModel[]): InvoiceModel[] {
    return invoices.sort(
      ({ invoiceDate: invoiceDate1, creationDate: creationDate1 }, {
        invoiceDate: invoiceDate2, creationDate: creationDate2,
      }) => {
        return invoiceDate2.toMillis() - invoiceDate1.toMillis() || creationDate2.toMillis() - creationDate1.toMillis();
      },
    );
  }

  async fetchAgencyInvoices(agencyId: string, fromDate?: number, toDate?: number) {
    const rangeTime: OptionalTimePeriodRequest = {};

    if (fromDate) {
      rangeTime.from = fromDate;
    }

    if (toDate) {
      rangeTime.to = toDate;
    }

    this.setInvoicesLoading(true);

    try {
      const agencyInvoicesResponse: InvoiceModel[] | null = await getAgencyInvoices(agencyId, rangeTime);
      runInAction(() => {
        if (agencyInvoicesResponse) {
          const sortedInvoices = this.sortInvoicesByRechargingMonthAndYear(agencyInvoicesResponse);
          this.setInvoices(sortedInvoices);

          const provisionInvoices = agencyInvoicesResponse.filter(invoice => invoice.invoiceType != 'DISSOCIATED_FEES');
          const ordersInvoices: OrderInvoices[] = provisionInvoices.map(mainInvoice => {
            const feesInvoice: InvoiceModel | undefined = agencyInvoicesResponse.find(invoice => invoice.invoiceType === 'DISSOCIATED_FEES' && invoice.orderUid === mainInvoice.orderUid);

            return {
              mainInvoice,
              feesInvoice,
              orderUid: mainInvoice.orderUid,
              agencyUid: mainInvoice.agencyUid,
              creationDate: mainInvoice.creationDate,
              orderReference: mainInvoice.invoiceReference,
              orderPeriod: mainInvoice.invoiceDate,
              paymentMethod: mainInvoice.paymentMethod,
            };
          });

          this.setOrdersInvoices(ordersInvoices);
        }
      });
      return agencyInvoicesResponse;
    } catch (error) {
      this.invoicesLoadedStatus = 'error agency invoices loaded';
    } finally {
      this.setInvoicesLoading(false);
    }
  }

  async fetchAgencyInvoiceBeneficiariesByDate(agencyId: string, invoiceDateString: string) {
    const invoiceDate: Timestamp = Timestamp.fromDate(new Date(invoiceDateString));

    this.setInvoiceBeneficiariesLoading(true);

    try {
      const agencyInvoicesByDateRes: string[] | null =
        await getInvoiceBeneficiariesByDate(agencyId, { invoiceDate });

      runInAction(() => {
        this.invoiceBeneficiariesByDateList = agencyInvoicesByDateRes;
        this.invoiceBeneficiariesLoadedStatus = 'success';
      });
    } catch (e) {
      this.invoiceBeneficiariesLoadedStatus = 'error agency invoices loaded';
    } finally {
      this.setInvoiceBeneficiariesLoading(false);
    }
  }
}
