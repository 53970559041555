import { adjustLocalTimestamp } from './../assets/utils/dates/getParsedDate.util';

export const combineDateTimeToTimestamp = (
  date: Date | string,
  time: Date | string,
  useUTC: boolean = true,
): number => {
  const dateObj = date instanceof Date ? date : new Date(date);
  const timeObj = time instanceof Date ? time : new Date(time);

  let result: Date;

  if (useUTC) {
    result = new Date(Date.UTC(
      dateObj.getFullYear(),
      dateObj.getMonth(),
      dateObj.getDate(),
      timeObj.getHours(),
      timeObj.getMinutes(),
      0,
    ));
  } else {
    result = new Date(dateObj);
    result.setHours(timeObj.getHours(), timeObj.getMinutes(), 0);
  }

  return adjustLocalTimestamp(result);
};