import React, { FC } from 'react';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Select } from 'ui/Select';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

export type Option = {
  label: string;
  value: number;
};

export interface SelectSinceDateProps {
  className?: string;
  options?: Option[];
  value: number;
  onChange: (value: number) => void;
}

export const computedDate = (valueInMonths: number = 0): Date => {
  let from: Date = new Date();
  from.setMonth(from.getMonth() - valueInMonths);
  return from;
};

export const SelectSinceDate: FC<SelectSinceDateProps> =
  ({
     className = '',
     options,
     value = 12,
     onChange,
   }) => {
    const { t } = useTranslation('accounting');

    const defaultOptions: Option[] = [
      { label: t('since6months'), value: 6 }, // value : number of months
      { label: t('since1year'), value: 12 },
      { label: t('since2years'), value: 24 },
      { label: t('sinceBeginning'), value: 120 },
    ];

    return (
      <Select
        className={clsx('bg-primary text-white text-center', className)}
        value={value}
        onChange={(event: SelectChangeEvent<number>) => onChange(+event.target.value)}
      >
        {(options ?? defaultOptions).map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    );
  };
