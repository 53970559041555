import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseCard from './BaseCard';
import { InvoiceModel } from '@assets/models/invoices/invoice.model';
import moment from 'moment/moment';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { useInstances } from 'react-ioc';
import { StatusLed } from '../../ui/StatusLed';

interface Props {
  invoice: InvoiceModel,
  onClick: () => void,
}

const InvoiceCard = (props: Props) => {
  const { t } = useTranslation('accounting');
  const [
    agenciesStore,
  ]: [AgenciesStore] = useInstances<[AgenciesStore]>(AgenciesStore);
  const { onClick, invoice } = props;

  const statusDate: Date = props.invoice.statusDate.toDate();

  const label: string = invoice.status === 'SUCCEEDED'
    ? `${props.invoice.paymentMethod === 'DIRECT_DEBIT'
      ? t('paidDirectDebit')
      : t('paidBankTransfer')} ${statusDate.toLocaleDateString()}`
    : t('toPay', { mode: props.invoice.paymentMethod === 'DIRECT_DEBIT' ? 'prélèvement' : 'virement' });

  const date = {
    label: t(`creationDateLabel_${invoice.invoiceType}`),
    value: moment(invoice.creationDate.toDate()).format('DD/MM/YYYY'),
  };

  const reference = {
    label: t(`invoiceTitle_${invoice.invoiceType}`),
    value: invoice.invoiceReference,
  };

  const dateOptions = { year: 'numeric', month: 'long' } as const;
  const monthAndYear: string = invoice.invoiceDate.toDate().toLocaleDateString('fr-FR', dateOptions);

  const numberFormat: Intl.NumberFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });
  const unformattedAmount = agenciesStore.currentAgency?.isManagedPaymentMode === false ? invoice.feesAmountTTC : invoice.netAmount;

  const amount = {
    label: t(`amount_${invoice.invoiceType}`),
    value: numberFormat.format(unformattedAmount),
  };

  return (
    <BaseCard onClick={onClick} buttonText={t(`seeDetails_STANDARD`)}>
      <StatusLed isActive={invoice?.status === 'SUCCEEDED'} label={label}/>
      <div>
        <p className={'text-sm'}>{date.label}</p>
        <p className={'font-bold'}>{date.value}</p>
      </div>
      <div>
        <p className={'text-sm'}>{reference.label}</p>
        <p className={'font-bold'}>{reference.value}</p>
      </div>
      <div>
        <p className={'font-bold'}>{monthAndYear}</p>
      </div>
      <div>
        <p className={'text-sm'}>{amount.label}</p>
        <p className={'font-bold'}>{amount.value}</p>
      </div>
    </BaseCard>
  );
};

export default InvoiceCard;
