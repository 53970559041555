import { MillesimeReceiptModel } from '../assets/models/millesime/MillesimeReceipt.model';
import { ProductType } from '../assets/models/products/Products.model';
import { callable } from '../Services/httpWrapper.service';
import { convertRestitutionReceiptFromCentsToEuros } from '../assets/utils/accountingDocuments/accountingDocument.util';
import { AgencyRestitutionReceiptModel } from '@assets/models/accountingDocuments/AgencyAccountingDocument.model';
import { getDocsWhere, QueryWhereElement } from '../Services/firebase.service';
import { convertCentToEuro } from '../assets/utils/functions/convertCentToEuro.util';

export async function getAgencyMillesimeReceipts<Product extends ProductType = ProductType>(agencyId: string, productType: ProductType): Promise<MillesimeReceiptModel<Product>[]> {
  return callable(
    'getAgencyMillesimeReceipts',
    { agencyId },
    { productType },
  ).then(receipts => receipts.map(convertRestitutionReceiptFromCentsToEuros),
  ) as Promise<MillesimeReceiptModel<Product>[]>;
}

export async function getRestitutionReceipts(agencyId: string, sinceDate: Date | null): Promise<AgencyRestitutionReceiptModel[]> {
  const queryWhere: QueryWhereElement<AgencyRestitutionReceiptModel>[] = [
    {
      fieldName: 'documentType',
      operator: '==',
      value: 'RestitutionReceipt:FOOD_VOUCHER',
    },
  ];

  if (sinceDate) {
    queryWhere.push({
      fieldName: 'creationDate',
      operator: '>=',
      value: sinceDate,
    });
  }

  const results = await getDocsWhere<AgencyRestitutionReceiptModel>(`/AGENCIES/${agencyId}/ACCOUNTING_DOCUMENTS`, queryWhere);

  return results.map(result => ({
    ...result,
    restitutionAmount: convertCentToEuro(result.restitutionAmount),
  }));
}


