import moment from 'moment/moment';
import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDeactivationReportCardStyles } from './DeactivationReportCardStyles';
import { AgencyRestitutionReceiptModel } from '@assets/models/accountingDocuments/AgencyAccountingDocument.model';

interface IDeactivationReportCard {
  deactivationReportKey: number;
  deactivationReport: AgencyRestitutionReceiptModel;
  openDeactivationReport: (deactivationReportKey: number) => void;
}

const DeactivationReportCard = (props: IDeactivationReportCard) => {
  const { t } = useTranslation('accounting');
  const deactivationReportCardClasses = useDeactivationReportCardStyles(props);

 const { deactivationReport, deactivationReportKey, openDeactivationReport } = props;

  return (
    <div className={deactivationReportCardClasses.block}>
      <div>
        <div className={deactivationReportCardClasses.statusBlock}>
          <div className={deactivationReportCardClasses.status}/>
          <div className={deactivationReportCardClasses.label}>
            {t(`deactivationReportDetails.${deactivationReport.paybackSubStatus}`)}
          </div>
        </div>
        <div className={deactivationReportCardClasses.label}>
          {t('deactivationReportDetails.restitutionDate')}
        </div>
        <div className={deactivationReportCardClasses.value}>
          {moment(deactivationReport.creationDate.toDate()).format('DD/MM/YYYY')}
        </div>
        <div className={deactivationReportCardClasses.label}>
          {t('deactivationReportDetails.releveNumber')}
        </div>
        <div className={deactivationReportCardClasses.value}>
          {deactivationReport.invoiceReference}
        </div>
        <div className={deactivationReportCardClasses.label}>
          {t(`deactivationReportDetails.quiteDispositif`)}
        </div>
        <div className={deactivationReportCardClasses.value}>
          {`${deactivationReport.beneficiaryFirstName} ${deactivationReport.beneficiaryLastName.toUpperCase()}`}
        </div>
        <div className={deactivationReportCardClasses.label}>
          {t(`deactivationReportDetails.amount`)}
        </div>
        <div className={deactivationReportCardClasses.value}>
          {(deactivationReport.restitutionAmount).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}
        </div>
      </div>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => openDeactivationReport(deactivationReportKey)}>
        {t('deactivationReportDetails.seeDetails')}
      </Button>
    </div>
  );
};

export default DeactivationReportCard;