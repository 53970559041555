import Yup from '../../../i18n/validation';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { FunctionFormComponent } from './FunctionFormComponent.model';
import { InputField } from '../../../ui/Input';
import { SwitchWithLabel } from '../../../ui/Switch';
import { DatePicker } from '../../../ui/DatePicker';

export const FIRST_RIGHT_DATE = 'firstRightDate';
export const DAILY_VOUCHER_USAGE_LIMIT = 'dailyVoucherUsageLimit';
export const MONTHLY_VOUCHER_USAGE_LIMIT = 'monthlyVoucherUsageLimit';
export const ACTIVE_SATURDAYS = 'activeSaturdays';
export const ACTIVE_SUNDAYS_AND_HOLIDAYS = 'activeSundaysAndHolidays';

const ActivityFormComponent_Discrete: FunctionFormComponent = observer(({
                                                                          formik, beneficiary, isEditMode = false,
                                                                          disableModification = false,
                                                                        }) => {
  const { t } = useTranslation('beneficiaries');

  return (
    <div className={'space-y-2'}>
      <h3 className={'font-bold'}>
        Activité
      </h3>
      <div className={'flex flex-col gap-2'}>
        <div className={'flex gap-2'}>
          <InputField
            id={DAILY_VOUCHER_USAGE_LIMIT}
            name={DAILY_VOUCHER_USAGE_LIMIT}
            label={t(DAILY_VOUCHER_USAGE_LIMIT)}
            value={formik.values[DAILY_VOUCHER_USAGE_LIMIT]}
            onChange={(e) => formik.setFieldValue(DAILY_VOUCHER_USAGE_LIMIT, Number(e.target.value))}
            onBlur={formik.handleBlur}
            type="number"
            required
            fullWidth
            error={formik.touched[DAILY_VOUCHER_USAGE_LIMIT] && Boolean(formik.errors[DAILY_VOUCHER_USAGE_LIMIT])}
            errorMessage={formik.touched[DAILY_VOUCHER_USAGE_LIMIT] && formik.errors[DAILY_VOUCHER_USAGE_LIMIT] as string}
            disabled={disableModification}
          />
          <InputField
            id={MONTHLY_VOUCHER_USAGE_LIMIT}
            name={MONTHLY_VOUCHER_USAGE_LIMIT}
            label={t(MONTHLY_VOUCHER_USAGE_LIMIT)}
            value={formik.values[MONTHLY_VOUCHER_USAGE_LIMIT]}
            onChange={(e) => formik.setFieldValue(MONTHLY_VOUCHER_USAGE_LIMIT, Number(e.target.value))}
            onBlur={formik.handleBlur}
            type="number"
            required
            fullWidth
            error={formik.touched[MONTHLY_VOUCHER_USAGE_LIMIT] && Boolean(formik.errors[MONTHLY_VOUCHER_USAGE_LIMIT])}
            errorMessage={formik.touched[MONTHLY_VOUCHER_USAGE_LIMIT] && formik.errors[MONTHLY_VOUCHER_USAGE_LIMIT] as string}
            disabled={disableModification}
          />
        </div>
        <div className={'flex gap-2 pb-4'}>
          <DatePicker
            id={FIRST_RIGHT_DATE}
            name={FIRST_RIGHT_DATE}
            label={t(FIRST_RIGHT_DATE)}
            formik={formik}
            required
            fullWidth
            disabled={isEditMode || disableModification}
          />
          <span className={'w-full'}></span>
        </div>
      </div>

      <div className={'space-y-2'}>
        <SwitchWithLabel
          label={'Travaille les samedis'}
          checked={formik.values[ACTIVE_SATURDAYS] ?? false}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(ACTIVE_SATURDAYS, event.target.checked)}
          name="checkedA"
          disabled={disableModification}
        />

        <SwitchWithLabel
          label={'Travaille les dimanches et jours fériés'}
          checked={formik.values[ACTIVE_SUNDAYS_AND_HOLIDAYS] ?? false}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(ACTIVE_SUNDAYS_AND_HOLIDAYS, event.target.checked)}
          name="checkedA"
          disabled={disableModification}
        />
      </div>

    </div>
  );
}) as FunctionFormComponent;

ActivityFormComponent_Discrete.getValidationSchema = () => {
  return Yup.object().shape({
    [DAILY_VOUCHER_USAGE_LIMIT]: Yup.number()
      .min(1, 'Ce nombre doit être compris entre 1 et 3')
      .max(3, 'Ce nombre doit être compris entre 1 et 3')
      .required(),
    [MONTHLY_VOUCHER_USAGE_LIMIT]: Yup.number()
      .min(1, 'Ce nombre doit être compris entre 1 et 23')
      .max(23, 'Ce nombre doit être compris entre 1 et 23')
      .required(),
  });
};

ActivityFormComponent_Discrete.getInitialValues = (beneficiary?: BeneficiaryModel) => {
  return {
    [FIRST_RIGHT_DATE]: beneficiary?.firstRightDate
      ? moment(beneficiary.firstRightDate.toMillis()).format('YYYY-MM-DD')
      : moment().add(1, 'M').startOf('month').format('YYYY-MM-DD'),
    [DAILY_VOUCHER_USAGE_LIMIT]: beneficiary?.dailyVoucherUsageLimit ?? 1,
    [MONTHLY_VOUCHER_USAGE_LIMIT]: beneficiary?.monthlyVoucherUsageLimit ?? 23,
    [ACTIVE_SATURDAYS]: beneficiary?.activeSaturdays ?? false,
    [ACTIVE_SUNDAYS_AND_HOLIDAYS]: beneficiary?.activeSundaysAndHolidays ?? false,
  };
};

export default ActivityFormComponent_Discrete;
