import { getHumanMonth, getYear } from '../dates/dateTime.util';
import { getTimeStampEndOfDay, toDate } from '../dates/getParsedDate.util';
import {
  AgencyMillesimeReport,
  AgencyMillesimeReportState,
  BeneficiaryMillesimeReport,
  BeneficiaryMillesimeReportState,
} from '../../models/millesime/MillesimeReports.model';
import { ExportingBeneficiaryMillesimeReportModel } from '../../models/millesime/ExportingMillesimeReports.model';
import { ProductType } from '../../models/products/Products.model';
import { MillesimeMetadataModel, MillesimeScriptExecutionStatus } from '../../models/millesime/MillesimeMetadata.model';
import { convertCentToEuro } from '../functions/convertCentToEuro.util';

export const MILLESIME_SCRIPT_EXECUTION_MONTH_INDEX = 2;

export function getStartOfMillesimeDate(date: Date): Date {
  const millesimeYear = getMillesimeYear(date);

  return new Date(millesimeYear, MILLESIME_SCRIPT_EXECUTION_MONTH_INDEX, 1);
}

export function getEndOfMillesimeDate(date: Date): Date {
  const millesimeYear = getMillesimeYear(date) + 1;
  const nextMillesimeStartingDate: Date = new Date(millesimeYear, MILLESIME_SCRIPT_EXECUTION_MONTH_INDEX, 1);
  const endOfMillesimeDate: Date = toDate(nextMillesimeStartingDate.setDate(nextMillesimeStartingDate.getDate() - 1));

  return toDate(getTimeStampEndOfDay(endOfMillesimeDate));
}

export function getMillesimeYear(date: Date): number {
  return getHumanMonth(date) > 2 ? getYear(date) : getYear(date) - 1;
}

export function formatBeneficiaryMillesimeReportForExport(report: BeneficiaryMillesimeReport): ExportingBeneficiaryMillesimeReportModel {
  const defaultExportingReport: ExportingBeneficiaryMillesimeReportModel = {
    'Prénom': report.firstName,
    'Nom': report.lastName,
    'Total de crédits reçus': 'non disponible',
    'Total reporté depuis millésime précédent': 'non disponible',
    'Total de remboursements': 'non disponible',
    'Total de soldes non consommés': 'non disponible',
  };

  if (!beneficiaryReportIsInState(report, 'DONE')) {
    return defaultExportingReport;
  }

  return {
    'Prénom': report.firstName,
    'Nom': report.lastName,
    'Total de crédits reçus': `${report.totalAmountOfCredits}`,
    'Total reporté depuis millésime précédent': `${report.totalAmountOfCreditsReconductedFromPreviousMillesime}`,
    'Total de remboursements': `${report.totalAmountOfPaybacks}`,
    'Total de soldes non consommés': `${report.totalAmountOfUnusedCredits}`,
  };
}

export function metadataIsInState<Product extends ProductType, State extends MillesimeScriptExecutionStatus>(metadata: MillesimeMetadataModel<Product>, state: State): metadata is MillesimeMetadataModel<Product, State> {
  return metadata.scriptExecutionStatus === state;
}

export function agencyReportIsInState<Product extends ProductType, State extends AgencyMillesimeReportState>(report: AgencyMillesimeReport<Product>, state: State): report is AgencyMillesimeReport<Product, State> {
  return report.state === state;
}

export function beneficiaryReportIsInState<Product extends ProductType, State extends BeneficiaryMillesimeReportState>(report: BeneficiaryMillesimeReport<Product>, state: State): report is BeneficiaryMillesimeReport<Product, State> {
  return report.state === state;
}

export function convertAgencyMillesimeReportFromCentsToEuros(report: AgencyMillesimeReport): AgencyMillesimeReport {
  if (!agencyReportIsInState(report, 'DONE')) {
    return report;
  }

  return {
    ...report,

    totalAmountOfCredits: convertCentToEuro(report.totalAmountOfCredits),
    totalAmountOfCreditsReconductedFromPreviousMillesime: convertCentToEuro(report.totalAmountOfCreditsReconductedFromPreviousMillesime),
    totalAmountOfPaybacks: convertCentToEuro(report.totalAmountOfPaybacks),
    totalAmountOfUnusedCredits: convertCentToEuro(report.totalAmountOfUnusedCredits),
    restitutionAmount: convertCentToEuro(report.restitutionAmount),
  };
}

export function convertBeneficiaryMillesimeReportFromCentsToEuros(report: BeneficiaryMillesimeReport): BeneficiaryMillesimeReport {
  if (!beneficiaryReportIsInState(report, 'DONE')) {
    return report;
  }

  return {
    ...report,

    totalAmountOfCredits: convertCentToEuro(report.totalAmountOfCredits),
    totalAmountOfCreditsReconductedFromPreviousMillesime: convertCentToEuro(report.totalAmountOfCreditsReconductedFromPreviousMillesime),
    totalAmountOfPaybacks: convertCentToEuro(report.totalAmountOfPaybacks),
    totalAmountOfUnusedCredits: convertCentToEuro(report.totalAmountOfUnusedCredits),

    restitutionAmount: convertCentToEuro(report.restitutionAmount),
  };
}
