import React, { useMemo } from 'react';
import { FormikRadioSet } from 'ui/RadioSet';
import { observer } from 'mobx-react-lite';
import { FunctionFormComponent } from './FunctionFormComponent.model';
import { TimePicker } from '../../../ui/TimePicker';
import { DatePicker } from '../../../ui/DatePicker';
import { useTranslation } from 'react-i18next';
import Yup from '../../../i18n/validation';

export const INVITATION_MAIL_SCHEDULING = 'invitationMailScheduling';
export const INVITATION_MAIL_SCHEDULING_VALUES = {
  IMMEDIATE: 'IMMEDIATE' as const,
  SCHEDULED: 'SCHEDULED' as const
};
export const INVITATION_SEND_DATE = 'invitationSendDate';
export const INVITATION_SEND_TIME = 'invitationSendTime';

export const CommunicationComponent: FunctionFormComponent = observer((props) => {
  const {
    formik, beneficiary, isEditMode, disableModification, showTitle = true
  } = props;

  const { t } = useTranslation('beneficiaries');

  const showDateTimePickers: boolean = useMemo(() => {
    return formik.values[INVITATION_MAIL_SCHEDULING] === INVITATION_MAIL_SCHEDULING_VALUES.SCHEDULED;
  }, [formik.values[INVITATION_MAIL_SCHEDULING]]);

  const texts: { schedule: string; sendNow: string } = useMemo(() => ({
    sendNow: !showTitle ? 'Envoyer les mails d\'invitation maintenant' : 'Envoyer le mail d\'invitation maintenant',
    schedule: !showTitle ? 'Programmer une date d\'envoi des invitations' : 'Programmer une date d\'envoi de l\'invitation'
  }), [showTitle]);

  return (
    <div>
      {showTitle &&
          <h3 className={'font-bold'}>
              Communication
          </h3>
      }

      <FormikRadioSet
        id={INVITATION_MAIL_SCHEDULING}
        name={INVITATION_MAIL_SCHEDULING}
        formik={formik}
        required
        disabled={isEditMode && !beneficiary?.isActive || disableModification}
        options={[
          { value: INVITATION_MAIL_SCHEDULING_VALUES.IMMEDIATE, label: texts.sendNow },
          { value: INVITATION_MAIL_SCHEDULING_VALUES.SCHEDULED, label: texts.schedule }
        ]}
      />

      {showDateTimePickers &&
          (<div className={'pt-2 pb-10 gap-2 flex justify-end w-full'}>
              <DatePicker
                  id={INVITATION_SEND_DATE}
                  name={INVITATION_SEND_DATE}
                  label={t(INVITATION_SEND_DATE)}
                  formik={formik}
                  required
              />

              <TimePicker
                  id={INVITATION_SEND_TIME}
                  name={INVITATION_SEND_TIME}
                  label={t(INVITATION_SEND_TIME)}
                  formik={formik}
                  required
              />
          </div>)
      }

    </div>
  )
}) as FunctionFormComponent

CommunicationComponent.getInitialValues = () => {
  return {
    [INVITATION_MAIL_SCHEDULING]: INVITATION_MAIL_SCHEDULING_VALUES.IMMEDIATE,
    [INVITATION_SEND_DATE]: null,
    [INVITATION_SEND_TIME]: null
  };
};

CommunicationComponent.getValidationSchema = () => {
  return Yup.object().shape({
    [INVITATION_MAIL_SCHEDULING]: Yup.string()
      .required('Cette information est obligatoire'),
    [INVITATION_SEND_DATE]: Yup.mixed().when(INVITATION_MAIL_SCHEDULING, {
      is: INVITATION_MAIL_SCHEDULING_VALUES.SCHEDULED,
      then: Yup.date()
        .nullable()
        .required('Cette information est obligatoire')
        .min(new Date(), 'la date doit être supérieure à aujourd\'hui')
        .typeError('Format de date invalide'),
      otherwise: Yup.mixed().nullable()
    }),
    [INVITATION_SEND_TIME]: Yup.mixed().when(INVITATION_MAIL_SCHEDULING, {
      is: INVITATION_MAIL_SCHEDULING_VALUES.SCHEDULED,
      then: Yup.date()
        .nullable()
        .required('Cette information est obligatoire')
        .typeError('Format d\'heure invalide'),
      otherwise: Yup.mixed().nullable()
    })
  });
};