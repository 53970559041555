import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { formatCurrencyToEuro, formatDateToLocaleString } from '../Utils/format.utils';
import { OrderInvoices } from '../Stores/AgencyInvoice.store';
import { getAgencyOrderProvision, getAgencyOrderProvisionSummary } from '../Services/ordersAPI.service';
import { BeneficiaryCreditModel } from '@assets/models/orders/Order.model';
import { isDefaultClassification } from '../assets/utils/agencies/beneficiaryClassification.util';
import {
  BeneficiaryClassificationProvisionSummary,
} from '@assets/models/beneficiaries/BeneficiaryClassification.model';

import { ReactComponent as ArrowSvg } from './../assets/svg/arrow.svg';
import { ReactComponent as CloseSvg } from '../assets/svg/close.svg';
import { Button } from '../ui/Buttons/Button';
import { DataSpreadsheet, DEFAULT_PAGE_SIZE } from '../ui/DataSpreadsheet';
import { Spinner } from '../ui/Spinner';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { PaginationResponse } from '@assets/responses/PagingCollection.response';

export interface IReloadingDetailsComponentProps {
  orderInvoices: OrderInvoices;
  onClose: () => void;
}

const dataGridColumnsDefinition: GridColDef[] = [
  {
    field: 'beneficiaryName',
    headerName: 'Bénéficiaire',
    flex: 1,
  },
  {
    field: 'numberOfRechargingDays',
    headerName: 'Jours travaillés',
    flex: 1,
  },
  {
    field: 'contractFacialValue',
    headerName: 'Droit quotidien',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const contractAgencyPart = params.row?.contractAgencyPart;
      return (
        <span>{contractAgencyPart ? formatCurrencyToEuro(contractAgencyPart) : 'N/A'}</span>
      );
    },
  },
  {
    field: 'provisionAmount',
    headerName: 'Montant total',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const provisionAmount = params.row?.provisionAmount;
      return (
        <span>{provisionAmount ? formatCurrencyToEuro(provisionAmount) : 'N/A'}</span>
      );
    },
  },
];

const OrderDetailsComponent: FunctionComponent<IReloadingDetailsComponentProps> = (props: IReloadingDetailsComponentProps) => {
  const {
    orderInvoices: { agencyUid, orderUid, paymentMethod, orderReference, creationDate, mainInvoice, feesInvoice },
    onClose,
  } = props;
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSummary, setIsLoadingSummary] = useState(true);
  const [beneficiaryProvisionList, setBeneficiaryProvisionList] = useState([]);
  const { t } = useTranslation(['reloading', 'common']);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: DEFAULT_PAGE_SIZE,
    page: 0,
  });

  const [classifications, setClassifications] = useState<BeneficiaryClassificationProvisionSummary[]>([]);

  const defaultSort: GridSortItem = { field: 'beneficiaryName', sort: 'asc' };
  const [count, setCount] = useState(0);
  const [sort, setSort] = useState<GridSortItem>(defaultSort);

  useEffect(() => {
    setIsLoadingSummary(true);
    (async () => {
      const response = await getAgencyOrderProvisionSummary(agencyUid, orderUid);

      setClassifications(response.classifications);
      setIsLoadingSummary(false);
    })();
  }, []);

  useEffect(() => {
    const isDateAfterThreshold: boolean = creationDate.toMillis() >= new Date('2022/09/14').getTime();
    setIsDataAvailable(isDateAfterThreshold);
    setIsLoading(isDateAfterThreshold);

    if (isDateAfterThreshold) {
      setBeneficiaryProvisionList([]);
      (async () => {
        const { page: requiredPage, pageSize }: GridPaginationModel = paginationModel;
        const response: PaginationResponse<BeneficiaryCreditModel> = await getAgencyOrderProvision(agencyUid, orderUid, {
          requiredPage,
          pageSize,
          sort: sort.field as keyof BeneficiaryCreditModel,
          direction: sort.sort,
        });

        setBeneficiaryProvisionList(response.items);
        setCount(response.count);

        setIsLoading(false);
      })();
    }
  }, [creationDate, agencyUid, orderUid, paginationModel, sort]);

  const updateSort = useCallback((sortModel: GridSortModel) => {
    const [sort] = sortModel;

    setSort(sort ?? defaultSort);
  }, []);

  return (
    <div className={'h-screen p-10'}>

      <div className={'flex justify-between items-center pb-5'}>
        <h1>{`Détail du rechargement N°${orderReference} du ${formatDateToLocaleString(creationDate.toDate())}`}</h1>
        <CloseSvg onClick={onClose}
                  className={'text-primary cursor-pointer hover:text-primary/75 transition-colors ease-in-out duration-300'}/>
      </div>

      <p>Vous pouvez consulter, ici, la liste des rechargements effectués sur chacun des bénéficiaires concernés</p>

      <div className={'flex gap-4 my-10'}>
        {isLoadingSummary && (<Spinner size={'lg'}/>)}
        {
          classifications.map(classification => (
            <div key={classification.uid} className={'rounded-br20 bg-background px-6 py-4'}>
              {
                !(classifications.length === 1 && isDefaultClassification(classification)) && (
                  <div className={'font-bold'}>
                    {classification.name}
                  </div>
                )
              }
              <div className={'flex flex-col gap-4'}>
                <div>
                  <p className={'text-sm pb-2'}>Total jours travaillés</p>
                  <span className={'font-bold'}>{classification.numberOfWorkingDays}</span>
                </div>
                <div>
                  <p className={'text-sm pb-2'}>Total droits</p>
                  <span className={'font-bold'}>{formatCurrencyToEuro(classification.provisionAmount)}</span>
                </div>
              </div>
            </div>
          ))
        }
      </div>

      <h3 className={'font-normal tracking-wider'}>LISTE DES DROITS ATTRIBUÉS</h3>

      {isDataAvailable &&
          <DataSpreadsheet
              rows={beneficiaryProvisionList}
              columns={dataGridColumnsDefinition}
              rowCount={count}
              loading={isLoading}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              onSortModelChange={updateSort}
              getRowId={(row) => row.uid}
              paginationMode={'server'}
              sortingMode={'server'}
              showHeader
          />
      }

      {!isDataAvailable &&
          <Box className={'py-4 text-center text-sm'}>
              Nous sommes désolés, nous ne sommes pas en mesure d'afficher<br/>
              le détail des rechargements effectués avant le 14/09/2022.
          </Box>
      }

      <div className={'flex gap-4'}>
        {paymentMethod === 'DISSOCIATED_BANK_TRANSFER' && feesInvoice?.invoiceUrl
          ? <a href={feesInvoice.invoiceUrl} target="_blank" rel="noreferrer" className={'no-underline'}>
            <Button variant="contained">
              <p>Facture abonnement</p>
              <ArrowSvg className={'text-white'}/>
            </Button>
          </a>
          : null
        }

        {mainInvoice.invoiceUrl
          ? <a href={mainInvoice.invoiceUrl} target="_blank" rel="noreferrer" className={'no-underline'}>
            <Button variant="contained">
              <p>{paymentMethod === 'DISSOCIATED_BANK_TRANSFER' ? 'Relevé de provision' : 'Télécharger la facture'}</p>
              <ArrowSvg className={'text-white'}/>
            </Button>
          </a>
          : null
        }
      </div>
      <div className={'flex justify-end'}>
        <Button onClick={onClose}>
          {t('common:close')}
        </Button>
      </div>


    </div>
  );
};

export default OrderDetailsComponent;
