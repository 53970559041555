import {
  analyseUploadingFile, CsvRowEntity, FieldTestReport,
  FileTestResult,
  FileTestSet, RowTestResult,
  transformCsvEntityToModel,
} from '../Utils/Tester/BaseTester.service';
import {
  BeneficiaryExistsStatus,
  BeneficiaryRowTestResult,
  CsvBeneficiary,
} from '../Utils/Tester/BeneficiaryTester.service';
import { removeEmptyLines, trimCells } from '../Utils/CsvManipulation.service';
import { UploadBeneficiaryRequest } from '@assets/requests/beneficiaries/UploadBeneficiary.request';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import { DEFAULT_MANAGEMENT_UNIT_ID } from '../assets/utils/agencies/managementUnit.util';
import firebase from 'firebase/compat';
import { Timestamp } from '@models/Timestamp';

export interface BeneficiaryUploadFileResult extends FileTestResult<CsvBeneficiary> {
  rows: BeneficiaryRowTestResult[];
}

export function analyseBeneficiaryUploadingFile(agencyId: string, csvData: string[][], testSet: FileTestSet<CsvBeneficiary>, existingBeneficiaryList: BeneficiaryModel[]): BeneficiaryUploadFileResult {
  const [headersRow, ...dataRows]: string[][] = trimCells(csvData);
  const cleanDataRows: string[][] = removeEmptyLines(dataRows);

  try {
    const fileTestResult: FileTestResult<CsvBeneficiary> = analyseUploadingFile(headersRow, cleanDataRows, testSet);

    const uploadFileTestResult: BeneficiaryUploadFileResult = {
      ...fileTestResult,
      rows: [],
    };

    uploadFileTestResult.rows = fileTestResult.rows.map((rowTestResult: RowTestResult<CsvBeneficiary>): BeneficiaryRowTestResult => {
      const { firstName, lastName }: CsvRowEntity<CsvBeneficiary> = rowTestResult.csvEntity;

      const isRegistrationNumberExists: boolean = rowTestResult.errors
        .some((field: FieldTestReport<CsvBeneficiary>) => field.errorMessage === 'REGISTRATION_NUMBER_ALREADY_TAKEN');

      const isEmailExists: boolean = rowTestResult.errors
        .some((field: FieldTestReport<CsvBeneficiary>) => field.errorMessage === 'EMAIL_ALREADY_TAKEN');

      const userValidity: BeneficiaryExistsStatus = isBeneficiaryExists(isRegistrationNumberExists, isEmailExists, rowTestResult, existingBeneficiaryList);

      if (userValidity === 'USER_EXISTS') {
        rowTestResult.errors = rowTestResult.errors
          .filter((error: FieldTestReport<CsvBeneficiary>) => {
            return error.fieldName !== 'registrationNumber'
              && error.fieldName !== 'email'
              && error.fieldName !== 'firstRightDate';
          });
        rowTestResult.passed = !rowTestResult.errors.length;
      }

      const entity = {
        firstName,
        lastName,
        userValidity,
        ...rowTestResult,
      };

      const uploadBeneficiary: UploadBeneficiaryRequest = transformCsvEntityToRequestObject(agencyId, entity.csvEntity, testSet);

      return {
        ...entity,
        uploadModel: uploadBeneficiary,
      };
    })
      .filter((entity: BeneficiaryRowTestResult) => {
        const { uploadModel: uploadBeneficiary } = entity;

        const existingBeneficiary = existingBeneficiaryList.find((beneficiary: BeneficiaryModel) => beneficiary.email === uploadBeneficiary.email);

        if (!existingBeneficiary) {
          return true;
        }

        const hasChanges: boolean = Object.keys(uploadBeneficiary).some((propertyName: string) => {
          if (propertyName === 'iban') {
            return existingBeneficiary.ibanLastNumbers !== uploadBeneficiary[propertyName].slice(-4);
          }

          if (propertyName === 'firstRightDate') {
            // This field cannot be updated
            uploadBeneficiary.firstRightDate = existingBeneficiary.firstRightDate;
            return false;
          }

          return existingBeneficiary[propertyName] !== uploadBeneficiary[propertyName];
        });

        return hasChanges;
      })
      .sort((beneficiaryA: BeneficiaryRowTestResult, beneficiaryB: BeneficiaryRowTestResult) => {
        const fullNameA: string = (beneficiaryA.lastName + beneficiaryA.firstName).toLowerCase();
        const fullNameB: string = (beneficiaryB.lastName + beneficiaryB.firstName).toLowerCase();

        return fullNameA.localeCompare(fullNameB);
      });

    const hasFileErrors: boolean = uploadFileTestResult.errors && uploadFileTestResult.errors?.length > 0;
    const hasRowError: boolean = uploadFileTestResult.rows.some(row => row.errors.length > 0);

    uploadFileTestResult.passed = !hasFileErrors && !hasRowError;

    return uploadFileTestResult;
  } catch (e) {
    return {
      passed: false,
      errors: [{ passed: false, errorMessage: 'UNKNOWN_ERROR' }],
      rows: [],
    };
  }
}

function isBeneficiaryExists(isRegistrationNumberExists: boolean, isEmailExists: boolean, rowTestResult: RowTestResult<CsvBeneficiary>, existingBeneficiaryList: BeneficiaryModel[]): BeneficiaryExistsStatus {
  if (Object.values(rowTestResult.csvEntity).every((field: string) => field.trim() === '')) {
    return 'EMPTY_LINE';
  }
  if (!isRegistrationNumberExists && !isEmailExists) {
    return 'USER_UNKNOWN';
  }

  if (isRegistrationNumberExists && isEmailExists) {
    const { csvEntity }: RowTestResult<CsvBeneficiary> = rowTestResult;

    const userExist: boolean = existingBeneficiaryList.some((beneficiary: BeneficiaryModel) => {
      return beneficiary.registrationNumber === csvEntity.registrationNumber
        && beneficiary.email === csvEntity.email;
    });

    return userExist ? 'USER_EXISTS' : 'REGISTRATION_NUMBER_OR_EMAIL_EXISTS';
  }

  return 'REGISTRATION_NUMBER_OR_EMAIL_EXISTS';
}

function transformCsvEntityToRequestObject(agencyId: string, csvEntity: CsvBeneficiary, testSet: FileTestSet<CsvBeneficiary>): UploadBeneficiaryRequest {
  const entity: Record<string, any> = transformCsvEntityToModel(csvEntity, testSet.columns);
  const { classificationCode, agencyManagementUnitId, firstRightDate, ...rest } = entity;

  return {
    ...rest,
    agencyId,
    beneficiaryClassificationUid: classificationCode,
    agencyManagementUnitId: agencyManagementUnitId ?? DEFAULT_MANAGEMENT_UNIT_ID,
    ...(firstRightDate ? { firstRightDate: Timestamp.fromDate(new Date(firstRightDate)) } : {}),
  } as unknown as UploadBeneficiaryRequest;
}