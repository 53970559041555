import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { ReactComponent as CloseSvg } from '../../assets/svg/close.svg';
import { BeneficiaryModel } from '../../assets/models/beneficiaries/Beneficiary.model';
import { UploadBeneficiaryRequest } from '../../assets/requests/beneficiaries/UploadBeneficiary.request';
import warningIcon from '../../assets/warning.svg';
import FileUploadManager from '../../Component/Dropzone/FileUploadManager/FileUploadManager';
import SnackErrorComponent from '../../Component/SnackbarError/SnackErrorComponent';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { BeneficiariesStore } from '../../Stores/Beneficiaries.store';
import {
  removeEmptyLinesFromTestResult,
} from '../../Utils/CsvManipulation.service';
import {
  FileTestSet,
} from '../../Utils/Tester/BaseTester.service';
import {
  BeneficiaryRowTestResult,
  CsvBeneficiary,
  getUploadBeneficiaryTestSet,
} from '../../Utils/Tester/BeneficiaryTester.service';
import { AddBeneficiariesAnalysisManager } from './AddBeneficiariesAnalysisManager/AddBeneficiariesAnalysisManager';
import { BeneficiaryClassificationModel } from '@assets/models/beneficiaries/BeneficiaryClassification.model';
import { analyseBeneficiaryUploadingFile, BeneficiaryUploadFileResult } from '../../Utils/BeneficiaryUploader.service';
import { downloadEmptyCsv } from '../../Utils/CsvDownload.utils';
import {
  BeneficiariesFileUploadModel,
} from '@assets/models/beneficiaries/BeneficiariesFileUpload.model';
import { BeneficiariesUploadStore } from '../../Stores/BeneficiariesUpload.store';
import successIcon from '../../assets/mandateSignedSuccess.svg';
import { ReactComponent as ErrorSvg } from '../../assets/svg/mandate/mandateSignedError.svg';
import { ManagementUnitModel } from '@assets/models/agencies/ManagementUnit.model';
import { OrganizationInformationsModel } from '@assets/models/organizationInformations/OrganizationInformations.model';
import FileUploadAnalysisErrors from '../../Component/FileUploadAnalysis/FileUploadAnalysisErrors';
import { Button } from 'ui/Buttons/Button';
import { CommunicationComponent } from 'Beneficiaries/BeneficiaryDetails/FormParts/CommunicationComponent';
import { useFormik } from 'formik';
import { combineDateTimeToTimestamp } from './../../Utils/convertDate.utils';

export interface IAddBeneficiariesByUploadComponentProps {
  onClose: () => void;
}

type LocalStore = [AgenciesStore, BeneficiariesStore, BeneficiariesUploadStore];

const ErrorBlock: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className={'mx-auto mt-1 flex flex-col'}>
      <div className={'bg-status-error/25 rounded-br10 shadow-none pb-10'}>
        <div className={'p-1 text-sm flex justify-center items-center'}>
          {children}
        </div>
      </div>
    </div>
  );
};

const ErrorLine: React.FC<{ key?: string | number; children: ReactNode }> = ({ key, children }) => {
  return (
    <Typography key={key} className={'text-sm flex'}>
      <img src={warningIcon} alt="warning"/>
      <span className={'text-status-error pl-2 font-bold'}>{children}</span>
    </Typography>
  );
};

const AddBeneficiariesByUploadComponent: FunctionComponent<IAddBeneficiariesByUploadComponentProps> =
  observer((props: IAddBeneficiariesByUploadComponentProps) => {
    const [
      agenciesStore,
      beneficiariesStore,
      beneficiariesUploadStore,
    ]: [AgenciesStore, BeneficiariesStore, BeneficiariesUploadStore] =
      useInstances<LocalStore>(AgenciesStore, BeneficiariesStore, BeneficiariesUploadStore);

    const { t } = useTranslation('beneficiaries');

    const existingBeneficiaryList: BeneficiaryModel[] = beneficiariesStore.beneficiariesList;
    const beneficiaryClassification: BeneficiaryClassificationModel[] = agenciesStore.currentAgencyBeneficiaryClassification;
    const currentAgencyManagementUnits: ManagementUnitModel[] = agenciesStore.currentAgencyAdminManagementUnits;
    const currentAgencyOrganizationInformation: OrganizationInformationsModel[] = agenciesStore.currentAgencyOrganizationInformations;

    const [file, setFile] = useState<File>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [canBeUploaded, setCanBeUploaded] = useState<boolean>(false);
    const [fileTestResult, setFileTestResult] = useState<BeneficiaryUploadFileResult | null>(null);
    const [showNewTestResult, setShowNewTestResult] = useState<boolean>(false);
    const [showAnalyseFileResult, setShowAnalyseFileResult] = useState<boolean>(false);
    const [showResultAnalyseCsvFile, setShowResultAnalyseCsvFile] = useState<boolean>(true);
    const [showUploadStarted, setShowUploadStarted] = useState<boolean>(false);
    const [showUploadStartFailed, setShowUploadStartFailed] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [hasErrorOfNbColumn, setHasErrorOfNbColumn] = useState<boolean>(false);

    const uploadBeneficiaryTestSet: FileTestSet<CsvBeneficiary> = useMemo(() => {
      return getUploadBeneficiaryTestSet(agenciesStore.currentAgency, existingBeneficiaryList, beneficiaryClassification, currentAgencyManagementUnits, currentAgencyOrganizationInformation);
    }, [agenciesStore.currentAgency, existingBeneficiaryList, beneficiaryClassification, currentAgencyManagementUnits, currentAgencyOrganizationInformation]);

    const resetState: () => void = useCallback(() => {
      setFileTestResult(null);
      setCanBeUploaded(false);
      setShowResultAnalyseCsvFile(true);
      setShowUploadStarted(false);
      setShowUploadStartFailed(false);
      setHasError(false);
      setHasErrorOfNbColumn(false);
      setShowAnalyseFileResult(false);
    }, []);

    const formik = useFormik({
      initialValues: CommunicationComponent.getInitialValues(),
      validationSchema: CommunicationComponent.getValidationSchema(),
      onSubmit: () => {},
    });

    useEffect(() => {
      resetState();
      formik.resetForm();
    }, [file, resetState]);

    useEffect(() => {
      setIsLoading(false);
    }, [fileTestResult]);

    function analyse(csvData: string[][], testSet: FileTestSet<CsvBeneficiary>): void {
      const fileTestResult: BeneficiaryUploadFileResult = analyseBeneficiaryUploadingFile(agenciesStore.currentAgency.uid, csvData, testSet, existingBeneficiaryList);
      setFileTestResult(fileTestResult);

      const incorrectColumnsError = fileTestResult.errors?.find(error => error.errorMessage === 'INVALID_COLUMNS');
      if (incorrectColumnsError) {
        setHasErrorOfNbColumn(true);
        setShowAnalyseFileResult(true);
        return;
      }

      const hasFileErrors: boolean = fileTestResult.errors && fileTestResult.errors?.length > 0;
      if (hasFileErrors) {
        setHasError(true);
        setShowAnalyseFileResult(false);
        return;
      }

      setHasError(!fileTestResult.passed);
      setShowAnalyseFileResult(true);
    }

    async function uploadBeneficiaries(): Promise<void> {
      const isScheduled: boolean = formik.values.invitationMailScheduling === 'SCHEDULED';
      let scheduledDateTime: number;

      if (isScheduled) {
        scheduledDateTime = combineDateTimeToTimestamp(
          formik.values.invitationSendDate,
          formik.values.invitationSendTime
        );
      }

      const beneficiariesToUpload: UploadBeneficiaryRequest[] = removeEmptyLinesFromTestResult(fileTestResult.rows)
        .map((entity: BeneficiaryRowTestResult) => ({
          ...entity.uploadModel,
          invitationScheduledDateTime: isScheduled ? scheduledDateTime : undefined
        }));

      const uploadRef: BeneficiariesFileUploadModel = await beneficiariesStore.uploadBeneficiaries(agenciesStore.currentAgency.uid, beneficiariesToUpload);

      setShowResultAnalyseCsvFile(false);

      if (beneficiariesStore.uploadBeneficiariesStatus === 'SUCCESS') {
        setShowUploadStarted(true);
        beneficiariesUploadStore.saveUploadRef(uploadRef);
      } else if (beneficiariesStore.uploadBeneficiariesStatus === 'ERROR') {
        setShowUploadStartFailed(true);
      }
    }

    const handleDownloadExampleFile = useCallback(() => {
      const columnNames: string[] = Object.values(uploadBeneficiaryTestSet.columns).map(column => column.name);

      downloadEmptyCsv(columnNames, 'addBeneficiaries.csv');
    }, []);

    return (
      <>
        <div className={'min-h-screen p-10'}>
          <div className={'flex justify-between pb-10'}>
            <h1>{t('uploadBeneficiariesDrawer.title')}</h1>
            <CloseSvg
              onClick={props.onClose}
              title={t('close')}
              className={'text-primary cursor-pointer hover:text-primary/75 transition-colors ease-in-out duration-300'}
            />
          </div>
          <h3 className={'font-normal text-base'}>{t('uploadBeneficiariesDrawer.subtitle')}</h3>

          {showResultAnalyseCsvFile &&
              <div className={'flex flex-col justify-center items-center'}>
                  <a onClick={handleDownloadExampleFile} href="#"
                     className={'py-10 text-primary'}>{t('uploadBeneficiariesDrawer.labelDownloadFile')}</a>
                  <FileUploadManager<CsvBeneficiary>
                      file={file}
                      setFile={setFile}
                      testSet={uploadBeneficiaryTestSet}
                      analyseUploadingFile={analyse}
                      fileTypeAccepted={'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      showNewTestResult={showNewTestResult}
                      setShowNewTestResult={setShowNewTestResult}
                  />

                {hasError && <div className={'flex w-full justify-end mt-10'}>
                    <Button onClick={props.onClose}>
                      {t('uploadBeneficiariesDrawer.hasError')}
                    </Button>
                </div>
                }

                {fileTestResult && <FileUploadAnalysisErrors fileTestResult={fileTestResult}/>}

                {showAnalyseFileResult
                  && <>
                        <AddBeneficiariesAnalysisManager
                            fileTestResult={fileTestResult.rows}
                            existingBeneficiaryList={existingBeneficiaryList}
                            setCanBeUploaded={setCanBeUploaded}
                            hasErrorOfNbColumn={hasErrorOfNbColumn}/>

                        {agenciesStore.currentAgency.hasScheduledOnboardingMode && (
                          <div className="w-full mt-4">
                            <CommunicationComponent
                              formik={formik}
                              isEditMode={false}
                              disableModification={false}
                              showTitle={false}
                            />
                          </div>
                        )}
                      </>
                }

                {canBeUploaded
                  && <div className={'flex w-full justify-end mt-10 gap-4'}>
                        <Button
                            onClick={props.onClose}
                            disabled={beneficiariesStore.isLoading}
                        >
                          {t('cancel')}
                        </Button>
                        <Button
                            onClick={() => uploadBeneficiaries()}
                            disabled={beneficiariesStore.isLoading ||
                              (formik.values.invitationMailScheduling === 'SCHEDULED' &&
                                (!formik.isValid || !formik.dirty))}
                            loading={beneficiariesStore.isLoading}
                        >
                          {t('uploadBeneficiariesDrawer.uploadFile')}
                        </Button>
                    </div>
                }
              </div>}

          {(showUploadStarted || showUploadStartFailed) && <>

            {showUploadStarted && (
              <div className={'px-4 space-y-2'}>
                <div className={'p-2 flex justify-center items-center'}>
                  <img src={successIcon} alt={'success'}/>
                </div>
                <p>L'import de vos collaborateurs a commencé.</p>
                <p>Vous pouvez suivre son avancement dans la notification en bas à gauche de votre écran.</p>
                <p>
                  Vous pouvez à tout moment fermer cet écran ou même quitter notre site, les informations sur cet import
                  seront présentes à votre prochain retour.
                </p>
              </div>
            )}

            {showUploadStartFailed && (
              <div className={'py-2 space-y-2'}>
                <div className={'p-2 flex justify-center items-center'}>
                  <ErrorSvg/>
                </div>
                <p>
                  Une erreur est survenue lors de la création de votre import.
                </p>
              </div>
            )}

              <div className={'flex justify-end w-full mt-10'}>
                  <Button onClick={props.onClose}>
                    {t('close')}
                  </Button>
              </div>
          </>
          }
        </div>
        {hasError && <SnackErrorComponent message={t('errorSnackBar')}/>}
      </>
    );
  });

export default AddBeneficiariesByUploadComponent;
