import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { AgencyInvoiceStore } from '../../Stores/AgencyInvoice.store';
import { RechargingViewStore } from '../../Stores/viewStore/RechargingView.store';
import { useInstances } from 'react-ioc';
import { IInvoiceCard } from '../../Models/Interfaces/IInvoiceCard.model';
import BaseCard from './BaseCard';

type LocalStore = [RechargingViewStore, AgencyInvoiceStore];

const OrderCard = observer((props: IInvoiceCard) => {
  const { orderInvoices } = props;
  const { orderReference, orderPeriod, mainInvoice } = orderInvoices;

  const dateOptions = { year: 'numeric', month: 'long' } as const;
  const invoiceDate = orderPeriod.toDate();
  const { t } = useTranslation('reloading');
  const [
    rechargingViewStore,
    agencyInvoiceStore,
  ]: [RechargingViewStore, AgencyInvoiceStore] = useInstances<LocalStore>(RechargingViewStore, AgencyInvoiceStore);

  const provision = mainInvoice.provisionsAmount;

  const handleCardClick: () => void = useCallback(() => {
    agencyInvoiceStore.setSelectedOrder(orderInvoices);
    rechargingViewStore.setOpenOrderDetails(true);
  }, [orderInvoices]);

  return (
    <BaseCard onClick={handleCardClick} buttonText={t('reloadingInvoice.reloadDetails')}>
      <div className={'flex flex-1 flex-col space-y-4'}>
        <div>
          <p className={'text-sm'}>{t('reloadingInvoice.reload')}</p>
          <p className={'font-bold'}>{invoiceDate.toLocaleDateString('fr-FR', dateOptions)}</p>
        </div>
        <div>
          <p className={'text-sm'}>{t('reloadingInvoice.ref')}</p>
          <p className={'font-bold'}>{orderReference}</p>
        </div>
        <div>
          <p className={'text-sm'}>{t('amount')}</p>
          <p className={'font-bold'}>
            {`${new Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR',
            }).format(provision)}`}
          </p>
        </div>
        <div>
          <p className={'text-sm'}>{t('reloadingInvoice.numberWorker')}</p>
          <p className={'font-bold'}>{mainInvoice.subscriptionsBeneficiaryCount}</p>
        </div>
      </div>
    </BaseCard>
  );
});

export default OrderCard;
