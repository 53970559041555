import React, { FC } from 'react';
import clsx from 'clsx';
import { FormControl } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';

interface BaseProps {
  id: string;
  label?: string;
  required?: boolean;
  error?: boolean;
  errorMessage?: string;
  value?: Date | null;
  onChange?: (value: Date | null) => void;
  onBlur?: () => void;
  name?: string;
  className?: string;
  disabled?: boolean;
  minTime?: Date;
  maxTime?: Date;
  helperText?: string;
  fullWidth?: boolean;
  formik?: FormikProps<any>;
}

const CLASSES = {
  root: 'group',
  base: {
    input: 'text-sm rounded-br30 h-16 bg-background disabled:opacity-50 text-primary border border-solid border-background hover:border-muted transition-all duration-300 ease-in-out',
    label: 'absolute top-2 left-0 z-50 px-6 w-full flex justify-between text-primary/50 text-sm',
    error: 'pt-1 text-sm transition-opacity duration-300 ease-in-out text-status-error',
  },
  states: {
    error: 'border-status-error',
    disabled: 'border-status-disabled bg-white hover:border-status-disabled',
  },
  mui: {
    notchedOutline: 'border-none',
    focused: 'ring-2 ring-primary ring-opacity-50',
    outline: {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        height: '100%',
        borderRadius: '30px',
        position: 'relative',
        alignItems: 'self-end',
        border: 'none',

        '& .MuiInputAdornment-root': {
          paddingBottom: '0',
          marginRight: '0.5rem',
        },

        '& .MuiOutlinedInput-input': {
          padding: '0 1.5rem',
          paddingBottom: '0.3rem',
        },
        '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline, &.Mui-error .MuiOutlinedInput-notchedOutline, &.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
  },
  errorMessage: 'pt-1 text-sm text-status-error',
};

export const TimePicker: FC<BaseProps> = (props) => {
  const {
    fullWidth, required, label, value, disabled, onChange, error, className, name, maxTime, minTime,
    errorMessage, formik, id
  } = props;

  const handleChange = (newValue: Date | null) => {
    if (formik && id) {
      formik.setFieldValue(id, newValue);
    }
    onChange?.(newValue);
  };

  const handleBlur = () => {
    if (formik && id) {
      formik.setFieldTouched(id, true);
      if (required && !formikValue) {
        formik.setFieldError(id, t('required'));
      }
    }
  };

  const formikValue = formik && id ? formik.values[id] : value;
  const formikError = formik && id ? formik.touched[id] && formik.errors[id] as string : undefined;
  const hasError = error || (Boolean(formikError) && formikValue === null);
  const finalErrorMessage = errorMessage || formikError;

  const { t } = useTranslation('common');

  return (
    <FormControl fullWidth={fullWidth} required={required} className="h-16">
      <div>
        {label && (
          <div className={CLASSES.base.label}>
            <div>{label}</div>
            {required && <div>{t('required')}</div>}
          </div>
        )}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiTimePicker
            value={formikValue}
            onChange={handleChange}
            minTime={minTime}
            maxTime={maxTime}
            disabled={disabled}
            views={['hours', 'minutes']}
            ampm={false}
            slotProps={{
              textField: {
                id,
                name,
                error: hasError,
                fullWidth,
                onBlur: handleBlur,
                className: clsx(
                  CLASSES.base.input,
                  disabled && CLASSES.states.disabled,
                  hasError && CLASSES.states.error,
                  className
                ),
                sx: CLASSES.mui.outline
              },
            }}
          />
        </LocalizationProvider>
      </div>
      <p className={clsx(CLASSES.base.error, hasError ? 'opacity-100' : 'opacity-0')}>
        {finalErrorMessage}
      </p>
    </FormControl>
  );
};