import React, { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import { BeneficiaryModel } from '../../../assets/models/beneficiaries/Beneficiary.model';
import { BeneficiaryRowTestResult } from '../../../Utils/Tester/BeneficiaryTester.service';
import AddBeneficiariesFromCsvError from './AddBeneficiariesFromCsvError';
import AddBeneficiariesFromCsvSuccess from './AddBeneficiariesFromCsvSuccess';

interface IAddBeneficiariesAnalysisManager {
  fileTestResult: BeneficiaryRowTestResult[];
  existingBeneficiaryList: BeneficiaryModel[];
  setCanBeUploaded: (value: boolean) => void;
  hasErrorOfNbColumn: boolean;
}

export const AddBeneficiariesAnalysisManager: FunctionComponent<IAddBeneficiariesAnalysisManager> = (props: PropsWithChildren<IAddBeneficiariesAnalysisManager>) => {
  const [fileError, setFileError] = useState<boolean>(false);

  function isErrorDetected(fileTestResult: BeneficiaryRowTestResult[]): void {
    if (props.hasErrorOfNbColumn) {
      setFileError(true);
      return;
    }
    fileTestResult.forEach((row: BeneficiaryRowTestResult) => {
      if (!row.passed) {
        setFileError(true);
      }
    });
  }

  useEffect(() => {
    isErrorDetected(props.fileTestResult);

    if (props.fileTestResult.length > 0 && !fileError) {
      props.setCanBeUploaded(true);
    } else {
      props.setCanBeUploaded(false);
    }
  }, [props.fileTestResult, fileError]);

  return (
    <div className={'w-2/3 mt-10'}>
      {fileError
        ? <AddBeneficiariesFromCsvError
          fileTestResult={props.fileTestResult}
          hasErrorOfNbColumn={props.hasErrorOfNbColumn}
        />

        : <AddBeneficiariesFromCsvSuccess
          fileTestResult={props.fileTestResult}
        />
      }
    </div>
  );
};
