import { manageError } from '../errors';

export type TextCase = 'lower' | 'upper' | 'capitalized'

export function prependStrings<T extends string>(prepend: T, elems: string[]): string[] {
  return elems.map(prependString.bind(null, prepend));
}

export function prependString<T extends string>(prepend: T, elem: string): string {
  return `${prepend}_${elem}`;
}

export function removePrependString(prepended: string): string {
  if (!prepended) {
    manageError({
      code: 'failed-precondition', message: 'Absence de chaîne à controller', statusCode: 403,
    });
  }
  const arrayString: string[] = prepended.split('_');
  if (arrayString.length <= 1) {
    manageError({
      code: 'not-found', message: 'l\'utilisateur est rattaché à aucune application', statusCode: 403,
    });
  }
  const [, afterPrepend] = arrayString;
  return afterPrepend;
}

// Returns the text with first letter uppercase
export function capitalizeString(text: string) {
  return `${text.substring(0, 1).toUpperCase()}${text.substring(1).toLowerCase()}`;
}

export function stringFormatter(str: string, textCase: TextCase = 'lower'): string {
  switch (textCase) {
    case 'upper':
      return str.toUpperCase();
    case 'lower':
      return str.toLowerCase();
    case 'capitalized':
      return capitalizeString(str);
    default:
      return str;
  }
}

