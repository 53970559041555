import { AgencyModel, ContractSpecificationModel, ContractType } from '../../models/agencies/Agency.model';

type ContractTypesDictionary = {
  [key in ContractType]: ContractSpecificationModel
}

const CONTRACT_TYPES: ContractTypesDictionary = {
  STANDARD: {
    productType: 'FOOD_VOUCHER',
    useManagedPayment: true,
    creditRedemptionMethod: 'LINEAR',
    pricingMethod: 'PREPAID',
    useCustomMaskLists: false,
    transactionSources: ['SCAN_CB', 'OPEN_BANKING'],
    paymentTargets: ['BENEFICIARY'],
  },
  GEOGRAPHIC_RESTRICTION: {
    productType: 'FOOD_VOUCHER',
    useManagedPayment: true,
    creditRedemptionMethod: 'LINEAR',
    pricingMethod: 'PREPAID',
    useCustomMaskLists: true,
    transactionSources: ['SCAN_CB', 'OPEN_BANKING'],
    paymentTargets: ['BENEFICIARY'],
  },
  ONE_SHOT: {
    productType: 'FOOD_VOUCHER',
    useManagedPayment: true,
    creditRedemptionMethod: 'DISCRETE',
    pricingMethod: 'PREPAID',
    useCustomMaskLists: false,
    transactionSources: ['SCAN_CB'],
    paymentTargets: ['BENEFICIARY'],
  },
  PUBLIC_MEAL_SUBSIDY: {
    productType: 'FOOD_VOUCHER',
    useManagedPayment: false,
    creditRedemptionMethod: 'DISCRETE',
    pricingMethod: 'PAY_AS_YOU_GO',
    useCustomMaskLists: false,
    transactionSources: ['QR_CODE'],
    paymentTargets: ['BUSINESS_ENTITY'],
  },
};

export function getContractSpecification(agency: AgencyModel): ContractSpecificationModel {
  const { contractType = 'STANDARD' } = agency;

  return CONTRACT_TYPES[contractType];
}