import { Button } from '../ui/Buttons/Button';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Timestamp } from 'firebase/firestore';
import { EmailingJobModel } from '@assets/models/scheduler/Job.model';

interface Props {
  job: EmailingJobModel;
  onModify: () => void;
}

export const ScheduledBeneficiariesInfo: React.FC<Props> = (props) => {
  const { job, onModify } = props;
  const { t } = useTranslation('beneficiaries');

  const beneficiariesCount: number = job.beneficiariesIds.length;
  const scheduledDateSeconds: number = (job.scheduledDate as unknown as Timestamp).seconds;

  const { formattedDate, formattedTime }: { formattedDate: string; formattedTime: string } = useMemo(() => {
    const dateObj: Date = new Date(scheduledDateSeconds * 1000);
    return {
      formattedDate: dateObj.toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      }),
      formattedTime: dateObj.toLocaleTimeString('fr-FR', {
        hour: '2-digit',
        minute: '2-digit',
      })
    };
  }, [scheduledDateSeconds]);

  const message: string = useMemo(() => {
    return t('ScheduledBeneficiariesInfo.fullMessage', {
      count: beneficiariesCount,
      date: formattedDate,
      time: formattedTime
    });
  }, [t, beneficiariesCount, formattedDate, formattedTime]);

  return (
    <div className={'bg-[#FBF2E9] rounded-br10 w-full flex items-center justify-between px-6 py-2'}>
      <p>{message}</p>
      <Button onClick={onModify}>
        {t('ScheduledBeneficiariesInfo.confirmation')}
      </Button>
    </div>
  );
};
