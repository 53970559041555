import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { FormikProps } from 'formik';

interface Option {
  value: string;
  label: string;
  helperText?: string;
  className?: string;
  condition?: boolean;
}

interface BaseRadioSetProps {
  id: string;
  label?: string;
  options: Option[];
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  name?: string;
  className?: string;
}

interface FormikRadioSetProps extends Omit<BaseRadioSetProps, 'value' | 'onBlur'> {
  formik: FormikProps<any>;
  name: string;
}

const CLASSES = {
  root: 'group',
  label: {
    wrapper: 'mb-2 flex justify-between text-primary/50 text-sm',
  },
  radio: {
    control: 'text-primary',
    disabled: 'text-primary/50',
    group: 'flex flex-row gap-4',
    label: 'text-sm text-primary',
    input: 'text-primary',
    helperText: 'pl-5 -mt-2',
  },
  errorMessage: 'pt-1 text-sm text-status-error',
};

export const RadioSet: FC<BaseRadioSetProps> = (props) => {
  const {
    label,
    options,
    required,
    disabled,
    error,
    errorMessage,
    value,
    onChange,
    onBlur,
    name,
    className,
  } = props;
  const { t } = useTranslation('common');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
  };

  return (
    <FormControl required={required} className={clsx(CLASSES.root, className)}>
      {label && (
        <div className={CLASSES.label.wrapper}>
          <div>{label}</div>
          {required && <div>{t('required')}</div>}
        </div>
      )}

      <RadioGroup
        name={name}
        value={value || ''}
        onChange={handleChange}
        onBlur={onBlur}
        className={CLASSES.radio.group}
      >
        {options.map((option, index) => (
          <React.Fragment key={index}>
            <FormControlLabel
              value={option.value}
              control={<Radio className={
                disabled ? CLASSES.radio.disabled : CLASSES.radio.control} disabled={disabled}/>}
              label={option.label}
              className={option.className}
            />
            {option.helperText && (
              <FormHelperText className={CLASSES.radio.helperText}>
                {option.helperText}
              </FormHelperText>
            )}
          </React.Fragment>
        ))}
      </RadioGroup>

      {error && errorMessage && (
        <p className={CLASSES.errorMessage}>{errorMessage}</p>
      )}
    </FormControl>
  );
};

export const FormikRadioSet: FC<FormikRadioSetProps> = ({ formik, name, ...props }) => {
  const error = formik.touched[name] && formik.errors[name];

  return (
    <RadioSet
      {...props}
      name={name}
      value={formik.values[name]}
      onChange={(value) => formik.setFieldValue(name, value)}
      onBlur={() => formik.setFieldTouched(name, true)}
      error={Boolean(error)}
      errorMessage={error as string}
    />
  );
};