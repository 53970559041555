 import { BeneficiaryModel } from '../../models/beneficiaries/Beneficiary.model';
import { getTimeStampEndOfDay } from '../dates/getParsedDate.util';

export function isBeneficiaryActive(beneficiary: BeneficiaryModel, rechargingYear: number, rechargingMonth: number): boolean {
  if (!beneficiary.isActive) {
    return false;
  }
  
  if (beneficiary.deactivationDate) {
    const deactivationDate: Date = beneficiary.deactivationDate.toDate();
    const deactivationMonth = deactivationDate.getMonth() + 1;
    const deactivationYear = deactivationDate.getFullYear();

    if (deactivationYear < rechargingYear) {
      return false;
    }

    if (deactivationYear === rechargingYear && deactivationMonth < rechargingMonth) {
      return false;
    }
  }

  if (!beneficiary.firstRightDate) {
    return false;
  }

  const firstRightDate: Date = beneficiary.firstRightDate.toDate();
  const firstRightMonth = firstRightDate.getMonth() + 1;
  const firstRightYear = firstRightDate.getFullYear();

  if (firstRightYear > rechargingYear) {
    return false;
  }

  return !(firstRightYear === rechargingYear && firstRightMonth > rechargingMonth);
}

export function isBeneficiaryActiveThisDay(beneficiary: BeneficiaryModel, date: number): boolean {
  const endOfThisDay: number = getTimeStampEndOfDay(date);
  if (!beneficiary.deactivationDate) {
    return true;
  }
  return beneficiary.deactivationDate.toMillis() > endOfThisDay;
}

export function isBeneficiaryActiveToday(beneficiary: BeneficiaryModel): boolean {
  return isBeneficiaryActiveThisDay(beneficiary, new Date().getTime());
}
