import { BeneficiaryModel } from '../../models/beneficiaries/Beneficiary.model';

export function allowToModifyBeneficiaryDeactivationConfig(beneficiary: BeneficiaryModel): boolean {
  if (!beneficiary.deactivationDetails) {
    return beneficiary.isActive;
  }

  const { state: deactivationState } = beneficiary.deactivationDetails;

  return deactivationState === 'CREATED';
}
