import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceModel } from '../../../assets/models/invoices/invoice.model';
import { AccountingViewStore } from '../../../Stores/viewStore/AccountingView.store';
import InvoiceDetailsComponent from './InvoiceDetails/InvoiceDetailsComponent';
import { ReactComponent as GlassesSvg } from '../../../assets/svg/glasses.svg';
import { Dialog } from '../../../ui/Dialog';
import { Spinner } from '../../../ui/Spinner';
import { InputField } from '../../../ui/Input';
import InvoiceCard from '../../../Component/Cards/InvoiceCard';
import { SelectSinceDate, computedDate } from '../../../Component/Selects/SelectSinceDate';

const InvoicesComponent: FunctionComponent<any> = observer(({ accountingStore }: {
  accountingStore: AccountingViewStore,
}) => {
  const [filteredInvoices, setFilteredInvoices] = useState<InvoiceModel[]>([]);
  const [currentInvoiceKey, setCurrentInvoiceKey] = useState<number | undefined>(undefined);
  const [selectValue, setSelectValue] = useState<number>(12);
  const { t } = useTranslation('accounting');

  const dateOptions = { year: 'numeric', month: 'long' } as const;

  const search: string = accountingStore.search;

  const applySearch = (newSearch: string, newInvoices: InvoiceModel[]) => {
    accountingStore.setSearch(newSearch);
    const filterInvoices = newInvoices.filter((i) => {
      const invoiceDate: Date =i.invoiceDate.toDate();
      const statusDate: Date = i.statusDate.toDate();
      const excludingTaxesAmount: number = i.feesAmountHT;
      const includingTaxesAmount: number = i.feesAmountTTC;

      return (
        (t('toPay', { mode: i.paymentMethod === 'DIRECT_DEBIT' ? 'prélèvement' : 'virement' }).toLocaleLowerCase().search(newSearch.toLocaleLowerCase()) !== -1 && i.statusDate) ||
        (`${i.paymentMethod === 'DIRECT_DEBIT' ? t('paidDirectDebit') : t('paidBankTransfer')}`.toLocaleLowerCase().search(newSearch.toLocaleLowerCase()) !== -1 && !i.statusDate) ||
        (i.statusDate && statusDate.toLocaleDateString().search(newSearch) !== -1) ||
        i.invoiceReference.toString().search(newSearch) !== -1 ||
        invoiceDate.toLocaleDateString('fr-FR', dateOptions).toLocaleLowerCase().search(newSearch) !== -1 ||
        includingTaxesAmount.toString().search(newSearch) !== -1 ||
        excludingTaxesAmount.toString().search(newSearch) !== -1
      );
    });
    setFilteredInvoices(filterInvoices);
  };

  const getInvoices = (from: Date) => {
    accountingStore.fetchAgencyInvoices(from.getTime())
      .then(() => {
        setFilteredInvoices(accountingStore.agencyInvoices);
        applySearch(search, accountingStore.agencyInvoices);
      })
      .catch(e => console.error(e));
  };

  useEffect(() => {
    if (accountingStore.agency && !!selectValue) {
      getInvoices(computedDate(selectValue));
    }
  }, [accountingStore.agency, selectValue]);

  const handleClickOpen = (key: number) => {
    setCurrentInvoiceKey(key);
    accountingStore.setOpenDialog(true);
  };

  const handleClose = () => {
    accountingStore.setOpenDialog(false);
  };

  return (
    <div className={'space-y-4'}>
      {accountingStore.isLoadingAgencyInvoices
        ? <Spinner size={'lg'}/>
        : <>
          <div className={'flex justify-between mt-6'}>
            <SelectSinceDate value={selectValue}
                             onChange={setSelectValue}
            />
            <div>
              <InputField
                placeholder={t('invoiceSearchPlaceholder')}
                onChange={(evt) => applySearch(evt.target.value, accountingStore.agencyInvoices)}
                value={search}
                size={'small'}
                startIcon={<GlassesSvg/>}
              />
            </div>
          </div>
          {filteredInvoices &&
              <>
                  <div className={'flex flex-wrap gap-6'}>
                    {filteredInvoices?.map((invoice, i) =>
                      <InvoiceCard key={invoice.uid} invoice={invoice} onClick={() => handleClickOpen(i)}/>,
                    )}
                  </div>
                  <Dialog className={''}
                          open={accountingStore.openDialog}
                          onClose={handleClose}
                          position={'center'}>
                      <InvoiceDetailsComponent invoice={filteredInvoices[currentInvoiceKey]} onClose={handleClose}/>
                  </Dialog>
              </>
          }
        </>
      }
    </div>
  );
});

export default InvoicesComponent;
