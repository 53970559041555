import { makeAutoObservable, runInAction } from 'mobx';
import { EmailingJobModel } from '@assets/models/scheduler/Job.model';
import {
  collection,
  CollectionReference,
  DocumentData, Firestore,
  getDocs, getFirestore,
  query,
  QuerySnapshot,
  where,
} from 'firebase/firestore';
import { Query } from '@firebase/firestore';

export class EmailingJobsStore {
  private db: Firestore = getFirestore();

  jobs: EmailingJobModel[] = [];
  error?: string = undefined;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  async getCreatedEmailingJobs(agencyId: string): Promise<void> {
    try {
      const emailingJobsPath: string = `AGENCIES/${agencyId}/EMAILING_JOBS`;
      const emailingJobsRef: CollectionReference<DocumentData, DocumentData> = collection(this.db, emailingJobsPath);
      const createdJobsQuery: Query<DocumentData, DocumentData> = query(emailingJobsRef, where('state', '==', 'CREATED'));

      const querySnapshot: QuerySnapshot<DocumentData, DocumentData> = await getDocs(createdJobsQuery);

      const jobsResult = querySnapshot.docs.map(doc => ({
        ...doc.data() as EmailingJobModel,
      }));

      runInAction(() => {
        this.jobs = jobsResult;
      });
    } catch (e) {
      runInAction(() => {
        this.jobs = [];
      });
      this.error = 'error on load Emailing Jobs';
      throw e;
    }
  }

}