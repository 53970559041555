import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { provider, useInstances } from 'react-ioc';
import {ReactComponent as CloseSvg} from '../../assets/svg/close.svg';
import { BeneficiaryModel } from '../../assets/models/beneficiaries/Beneficiary.model';
import { isBeneficiaryActive } from '../../assets/utils/beneficiaries/isBeneficiaryActive.util';
import { BeneficiariesStore } from '../../Stores/Beneficiaries.store';
import { CsvManagerStore } from '../../Stores/CsvManager.store';
import { AddNewBeneficiariesUploadViewStore } from '../../Stores/viewStore/AddNewBeneficiariesUploadView.store';
import { RechargingViewStore } from '../../Stores/viewStore/RechargingView.store';
import { StepperRechargingStore } from '../../Stores/viewStore/StepperRecharging.store';
import {
  hasDataTheCorrectNumberOfColumns,
  removeEmptyLines,
  removeEmptyLinesFromTestResult,
  trimCells,
} from '../../Utils/CsvManipulation.service';
import {
  beneficiaryRechargingKeysMap,
  CsvBeneficiaryRecharging,
  getBeneficiaryRechargingTestSet,
} from '../../Utils/Tester/BeneficiaryTester.service';
import FileUploadManager from '../../Component/Dropzone/FileUploadManager/FileUploadManager';
import { sortBeneficiaryList } from '../../Utils/sortBeneficiaryList';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { getFrenchMoneyFormat } from '../../assets/utils/money/currencyFormat.util';
import { HydratedBeneficiaryCreditData } from '../../assets/models/orders/Order.model';
import FileUploadAnalysisErrors from '../../Component/FileUploadAnalysis/FileUploadAnalysisErrors';
import { OrderPaymentViewStore } from '../../Stores/viewStore/OrderPaymentView.store';
import { AgencyInvoiceStore } from '../../Stores/AgencyInvoice.store';
import {
  analyseUploadingFile,
  FieldTestReport,
  FileTestResult,
  FileTestSet,
  RowTestResult,
} from '../../Utils/Tester/BaseTester.service';
import { Button } from '../../ui/Buttons/Button';
import { Toaster } from '../../ui/Toaster';
import RechargingOrderExistDialog from 'Component/Dialog/RechargingOrderExistDialog';
import { ReactComponent as WarningSvg } from '../../assets/svg/warning.svg';
import { ReactComponent as ArrowDown } from '../../assets/svg/arrows/arrowDown.svg';
import { DataSpreadsheet } from '../../ui/DataSpreadsheet';

type LocalStore = [AgenciesStore, RechargingViewStore, StepperRechargingStore, BeneficiariesStore, AddNewBeneficiariesUploadViewStore, OrderPaymentViewStore, AgencyInvoiceStore];
interface BeneficiaryRow {
  beneficiary: {
    registrationNumber: string;
    firstName: string;
    lastName: string;
  };
  beneficiaryClassification: {
    contractAgencyPart: number;
  };
  numberOfRechargingDays: number;

  [key: string]: any;
}

const AddNewBeneficiariesUpload: FunctionComponent = provider(
  CsvManagerStore,
  AddNewBeneficiariesUploadViewStore,
)(observer(() => {
  const [
    agenciesStore,
    rechargingViewStore,
    stepperRechargingStore,
    beneficiariesStore,
    addNewBeneficiariesUploadViewStore,
    orderPaymentViewStore,
    agencyInvoiceStore,
  ]: LocalStore = useInstances<LocalStore>(AgenciesStore, RechargingViewStore, StepperRechargingStore, BeneficiariesStore, AddNewBeneficiariesUploadViewStore, OrderPaymentViewStore, AgencyInvoiceStore);

  const { t } = useTranslation('reloading');
  const existingBeneficiaryList: BeneficiaryModel[] = beneficiariesStore.beneficiariesList;
  const numberOfColumns = 4;

  const [file, setFile] = useState<File>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [beneficiaryRechargingFileTestResult, setBeneficiaryRechargingFileTestResult] = useState<FileTestResult<CsvBeneficiaryRecharging> | null>(null);
  const [fileTestFailed, setFileTestFailed] = useState<boolean>(false);
  const [showNewTestResult, setShowNewTestResult] = useState<boolean>(false);
  const [hasErrorOfNbColumn, setHasErrorOfNbColumn] = useState<boolean>(false);
  const [hasDuplicateError, setHasDuplicateError] = useState<boolean>(false);
  const [hasRechargingError, setHasRechargingError] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [canBeUploaded, setCanBeUploaded] = useState<boolean>(false);
  const [duplicateRegistrationNumbers, setDuplicateRegistrationNumbers] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [undefinedKey, setUndefinedKey] = useState<string>('');
  const [alreadyHasOrderForSelectedMonth, setAlreadyHasOrderForSelectedMonth] = useState(false);

  const columnsBeneficiariesToRecharge: GridColDef<BeneficiaryRow>[] = [
    {
      field: 'registrationNumber',
      headerName: 'Matricule',
      flex: 2,
      renderCell: (params: GridRenderCellParams) => (
        <div className="w-full">
          {params.row.beneficiary.registrationNumber}
        </div>
      ),
      sortComparator: (v1, v2, param1, param2) =>
        param1.api.getRow(param1.id).beneficiary.registrationNumber
          .localeCompare(param2.api.getRow(param2.id).beneficiary.registrationNumber)
    },
    {
      field: 'firstName',
      headerName: 'Prénom',
      flex: 2,
      renderCell: (params: GridRenderCellParams) => (
        <div className="w-full">
          {params.row.beneficiary.firstName}
        </div>
      ),
      sortComparator: (v1, v2, param1, param2) =>
        param1.api.getRow(param1.id).beneficiary.firstName
          .localeCompare(param2.api.getRow(param2.id).beneficiary.firstName)
    },
    {
      field: 'lastName',
      headerName: 'Nom',
      flex: 2,
      renderCell: (params: GridRenderCellParams) => (
        <div className="w-full uppercase">
          {params.row.beneficiary.lastName}
        </div>
      ),
      sortComparator: (v1, v2, param1, param2) =>
        param1.api.getRow(param1.id).beneficiary.lastName
          .localeCompare(param2.api.getRow(param2.id).beneficiary.lastName)
    },
    {
      field: 'rechargingMonth',
      headerName: 'Mois',
      flex: 2,
      renderCell: () => (
        <div className="w-full">
          {getReloadingDateString(rechargingViewStore.selectedReloadingDate)}
        </div>
      ),
      sortable: false
    },
    {
      field: 'numberOfRechargingDays',
      headerName: 'Nb jours',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div className="w-full">
          {params.row.numberOfRechargingDays}
        </div>
      ),
      sortComparator: (v1, v2, param1, param2) =>
        param2.api.getRow(param2.id).numberOfRechargingDays -
        param1.api.getRow(param1.id).numberOfRechargingDays
    },
    {
      field: 'credits',
      headerName: 'Crédit',
      flex: 2,
      renderCell: (params: GridRenderCellParams) => (
        <div className="w-full">
          {getFrenchMoneyFormat(params.row.numberOfRechargingDays * params.row.beneficiaryClassification.contractAgencyPart)}
        </div>
      ),
      sortComparator: (v1, v2, param1, param2) => {
        const credit1 = param1.api.getRow(param1.id).numberOfRechargingDays *
          param1.api.getRow(param1.id).beneficiaryClassification.contractAgencyPart;
        const credit2 = param2.api.getRow(param2.id).numberOfRechargingDays *
          param2.api.getRow(param2.id).beneficiaryClassification.contractAgencyPart;
        return credit2 - credit1;
      }
    }
  ];

  const csvRechargingTestSet: FileTestSet<CsvBeneficiaryRecharging> = useMemo(() => {
    return getBeneficiaryRechargingTestSet(existingBeneficiaryList);
  }, [existingBeneficiaryList]);

  useEffect(() => {
    setIsLoading(false);
  }, [beneficiaryRechargingFileTestResult]);

  useEffect(() => {
    setBeneficiaryRechargingFileTestResult(null);
    setFileTestFailed(false);
    setHasRechargingError(false);
    setHasDuplicateError(false);
    setHasErrorOfNbColumn(false);
    setCanBeUploaded(false);
    setErrorMessage('');
    setUndefinedKey('');
    setHasError(false);
    addNewBeneficiariesUploadViewStore.setShowErrorDialog(false);
  }, [file]);

  function analyseRechargingFile(csvData: string[][], testSet: FileTestSet<CsvBeneficiaryRecharging>): void {
    const [headersRow, ...dataRows]: string[][] = trimCells(csvData);
    const cleanDataRows: string[][] = removeEmptyLines(dataRows);
    const hasCorrectNumberOfColumns: boolean = hasDataTheCorrectNumberOfColumns(headersRow, cleanDataRows, numberOfColumns);

    if (!hasCorrectNumberOfColumns) {
      setHasErrorOfNbColumn(true);
      setShowNewTestResult(true);
      return;
    }

    const referenceYear: string | undefined = cleanDataRows.find(([yearStr]: string[]) => !!yearStr
      && !Number.isNaN(Number(yearStr)))?.[0];

    const referenceMonth: string | undefined = cleanDataRows.find(([, monthStr]: string[]) => !!monthStr
      && !Number.isNaN(Number(monthStr)))?.[1];

    checkRechargingDateUniqueness(cleanDataRows, referenceYear, referenceMonth);
    checkForDuplicateRegistrationNumber(cleanDataRows);

    if (hasDuplicateError) {
      return;
    }

    try {
      const fileTestResult: FileTestResult<CsvBeneficiaryRecharging> = analyseUploadingFile(headersRow, cleanDataRows, testSet);
      const rechargingYearNumber: number = Number(referenceYear as string);
      const rechargingMonthNumber: number = Number(referenceMonth as string);

      checkInactiveBeneficiaries(fileTestResult.rows, rechargingYearNumber, rechargingMonthNumber);
      const hasError: boolean = !fileTestResult.passed || fileTestResult.rows.some((rowResult: RowTestResult<CsvBeneficiaryRecharging>) => !rowResult.passed);
      setFileTestFailed(hasError);
      addNewBeneficiariesUploadViewStore.setShowErrorDialog(hasError);

      if (hasError) {
        addNewBeneficiariesUploadViewStore.setCsvRowError(true);
        setBeneficiaryRechargingFileTestResult(fileTestResult);
      } else {
        setBeneficiariesToRecharge(fileTestResult.rows, rechargingYearNumber, rechargingMonthNumber);
      }
    } catch (e) {
      if (e?.message.includes('Error column naming')) {
        const undefinedKey = (e?.message as string).split(' ').reverse()[0];
        setErrorMessage(e?.message as string);
        setUndefinedKey(undefinedKey);
      }
      setHasError(true);
    }
  }

  function checkRechargingDateUniqueness(trimmedEmptyLines: string[][], referenceYear: string | undefined, referenceMonth: string | undefined): void {
    if (referenceMonth && referenceYear) {
      const hasDifferentRechargingParams: boolean = trimmedEmptyLines
        .some(([year, month]: string[]) => (!!year && year !== referenceYear)
          || (!!month && month !== referenceMonth),
        );
      setHasRechargingError(hasDifferentRechargingParams);
    }
  }

  function checkForDuplicateRegistrationNumber(trimmedEmptyLines: string[][]): void {
    const registrationNumberCounters = trimmedEmptyLines
      .reduce((counters: { [registrationNumber: string]: number }, [, , registrationNumber]: string[]) => {
        if (!counters[registrationNumber]) {
          counters[registrationNumber] = 0;
        }
        counters[registrationNumber] += 1;
        return counters;
      }, {});

    const duplicatedRegistrationNumbers: string[] = Object.keys(registrationNumberCounters)
      .filter((registrationNumber: string) => registrationNumberCounters[registrationNumber] > 1);

    setDuplicateRegistrationNumbers(duplicatedRegistrationNumbers);
    const hasDuplicateRegistrationNumber: boolean = duplicatedRegistrationNumbers.length > 0;
    setHasDuplicateError(hasDuplicateRegistrationNumber);
  }

  function checkInactiveBeneficiaries(fileTestResult: RowTestResult<CsvBeneficiaryRecharging>[], rechargingYearNumber: number, rechargingMonthNumber: number): void {
    fileTestResult
      .filter((rowResult: RowTestResult<CsvBeneficiaryRecharging>) => Object.values(rowResult.csvEntity).some((item: string) => item.trim() !== ''))
      .filter((rowResult: RowTestResult<CsvBeneficiaryRecharging>) => {
        const associatedBeneficiary: BeneficiaryModel | undefined = existingBeneficiaryList
          .find((beneficiary: BeneficiaryModel) => beneficiary.registrationNumber === rowResult.csvEntity.registrationNumber.trim());
        return associatedBeneficiary && !isBeneficiaryActive(associatedBeneficiary, rechargingYearNumber, rechargingMonthNumber);
      })
      .forEach((rowResult: RowTestResult<CsvBeneficiaryRecharging>) => {
        rowResult.passed = false;
        rowResult.errors.push({
          passed: false,
          errorMessage: 'NOT_ACTIVE_THIS_MONTH',
          fieldName: 'registrationNumber',
          columnName: 'registrationNumber',
          expectedValue: rowResult.csvEntity.registrationNumber.trim(),
        });
      });
  }

  function setBeneficiariesToRecharge(fileTestResult: RowTestResult<CsvBeneficiaryRecharging>[], rechargingYearNumber: number, rechargingMonthNumber: number): void {
    rechargingViewStore.setRechargingMonthCsv(rechargingYearNumber, rechargingMonthNumber);
    orderPaymentViewStore.setOrderDetails({
      ...orderPaymentViewStore.orderDetails,
      year: rechargingYearNumber,
      month: rechargingMonthNumber,
    });

    const beneficiariesToRecharge: BeneficiaryModel[] = removeEmptyLinesFromTestResult(fileTestResult)
      .map((row: RowTestResult<CsvBeneficiaryRecharging>) => {
        const beneficiaryToRecharge: BeneficiaryModel = existingBeneficiaryList.find(
          (beneficiary: BeneficiaryModel) => beneficiary.registrationNumber === row.csvEntity.registrationNumber.trim(),
        );
        beneficiaryToRecharge.numberOfWorkingDays = Number(row.csvEntity.numberOfWorkingDays.trim());

        return beneficiaryToRecharge;
      });

    const sortedBeneficiariesToRecharge: BeneficiaryModel[] = sortBeneficiaryList(beneficiariesToRecharge);

    beneficiariesStore.setBeneficiariesToRecharge(sortedBeneficiariesToRecharge);

    const totalNumberOfWorkingDays: number = sortedBeneficiariesToRecharge
      .map(({ numberOfWorkingDays }) => numberOfWorkingDays)
      .reduce((acc: number, value: number) => acc + value, 0);

    beneficiariesStore.setTotalDaysToRecharge(totalNumberOfWorkingDays);
    setCanBeUploaded(true);
  }

  const goToNextStep: () => void = useCallback(() => {
    const test: boolean = agencyInvoiceStore.invoices.some((invoice) => {
      const date: Date = invoice.invoiceDate.toDate();
      return date.getMonth() === rechargingViewStore.selectedReloadingDate.getMonth()
        && date.getFullYear() === rechargingViewStore.selectedReloadingDate.getFullYear();
    });
    if (!test) {
      return stepperRechargingStore.goToNextStep();
    }
    setAlreadyHasOrderForSelectedMonth(test);
  }, [rechargingViewStore.selectedReloadingDate, agencyInvoiceStore.invoices]);

  function hasNoErrors(): boolean {
    return !fileTestFailed
      && !hasDuplicateError
      && !hasRechargingError
      && !hasErrorOfNbColumn
      && !hasError
      && canBeUploaded;
  }

  function getReloadingDateString(date: Date): string {
    return date.toLocaleDateString('fr', { dateStyle: 'long' }).split(' ').slice(1).join(' ');
  }

  const beneficiaryCredits: HydratedBeneficiaryCreditData[] = useMemo(() => {
    return beneficiariesStore.listOfBeneficiariesToRecharge.map(beneficiary => {
      const classification = agenciesStore.currentAgencyBeneficiaryClassification?.find(classification => classification.uid === beneficiary.beneficiaryClassificationUid);

      return {
        beneficiary: beneficiary,
        classificationId: classification.uid,
        classificationVersion: classification.version,
        beneficiaryUid: beneficiary.uid,
        numberOfRechargingDays: beneficiary.numberOfWorkingDays,
        beneficiaryClassification: classification,
        userAccountId: beneficiary.geniusUserAccountId,
      };
    });
  }, [beneficiariesStore.listOfBeneficiariesToRecharge, agenciesStore.currentAgencyBeneficiaryClassification]);

  return (
    <>
      <div className={'w-full flex flex-col items-center space-y-2 pt-20 pb-4'}>
        <h2 className={'pb-4 font-bold text-xl'}>{t('titleChoice')}</h2>
        <p className={'text-sm'}>{t('titleUpload')}</p>
        <a className={'text-link hover:text-link/75'} href={'/files/modeleFichierCommande.csv'} download>
          {t('subTitleUpload')}
        </a>
      </div>

      <FileUploadManager<CsvBeneficiaryRecharging>
        file={file}
        setFile={setFile}
        testSet={csvRechargingTestSet}
        analyseUploadingFile={analyseRechargingFile}
        fileTypeAccepted={'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        showNewTestResult={showNewTestResult}
        setShowNewTestResult={setShowNewTestResult}
      />
      {hasNoErrors()
        ? <div className={'flex flex-col items-center'}>
          <h2>{t('recharginSuccessSubTitle')}</h2>
          <div className={'flex flex-col items-center justify-center space-y-4'}>
            <Accordion className={'border border-solid border-status-success rounded-br10 shadow-none mt-2 before:h-0 pb-4'}>
              <AccordionSummary
                expandIcon={<ArrowDown className={'w-5 text-primary'}/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={'rounded-b-br10 px-7 py-4 border-none'}
              >
                <Typography className={'text-sm flex justify-center'}>
                  <WarningSvg width={20} />
                  <span
                    className={'pl-4 font-bold'}>{t('uploadRechargingSuccesMessage', {
                    beneficiariesToRechargeCount: beneficiariesStore.listOfBeneficiariesToRecharge.length,
                    totalDays: beneficiariesStore.totalDaysToRecharge,
                  })}</span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ width: '800px' }}>
                <DataSpreadsheet
                  idKey={'beneficiaryUid'}
                  rows={beneficiaryCredits}
                  loading={!agenciesStore.isCurrentAgencyLoaded || beneficiariesStore.isLoading}
                  columns={columnsBeneficiariesToRecharge}
                />
              </AccordionDetails>

            </Accordion>
            <div className={'flex justify-items-end gap-4'}>
              <Button variant="contained" onClick={() => stepperRechargingStore.goToPreviousStep()}>
                {t('cancelBtn')}
              </Button>
              <Button variant="contained" onClick={goToNextStep}>
                {t('proceedBtn')}
              </Button>
            </div>
          </div>
        </div>
        : fileTestFailed && <h2 className={'text-center pt-10'}>{t('recharginErrorSubTitle')}</h2>
      }
      {hasErrorOfNbColumn && <div className={'w-1/2 pt-10 mx-auto'}>
          <Accordion className={'border border-solid border-status-error rounded-br10 py-4'}>
              <AccordionSummary
                  expandIcon={<ArrowDown className={'w-5 text-primary'}/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={'rounded-b-br10 px-7 py-4'}
              >
                  <Typography className={'text-sm flex'}>
                      <WarningSvg width={20} />
                      <span className={'pl-4 font-bold'}>
                        Erreur sur le nombre de colonnes présentes. Il doit y avoir {numberOfColumns} colonnes.
                      </span>
                  </Typography>
              </AccordionSummary>
          </Accordion>
      </div>}
      {hasDuplicateError && <div className={'w-1/2 pt-10 mx-auto'}>
          <div className={'flex flex-col border border-solid border-status-error rounded-br10 p-2'}>

              <div className={'text-sm flex flex-col justify-center items-center p-1 px-7'}>
                {duplicateRegistrationNumbers.map((item: string, index: number) =>
                  <Typography key={index} className={'text-sm flex'}>
                    <WarningSvg width={20} />
                    <span className={'pl-4 font-bold'}>
                    Le matricule {item} porte plusieurs commandes
                  </span>
                  </Typography>)}

              </div>
          </div>
      </div>}
      {hasRechargingError && <div className={'w-1/2 pt-10 mx-auto'}>
          <div className={'flex flex-col border border-solid border-status-error rounded-br10 p-2'}>
              <div className={'text-sm flex flex-col justify-center items-center p-1 px-7'}>
                  <Typography className={'text-sm flex'}>
                      <WarningSvg width={20} />
                      <span className={'pl-4 font-bold'}>
                      Le fichier de commande ne doit porter que sur un seul mois
                    </span>
                  </Typography>
              </div>
          </div>
      </div>}

      {beneficiaryRechargingFileTestResult &&
          <FileUploadAnalysisErrors fileTestResult={beneficiaryRechargingFileTestResult}/>}

      {fileTestFailed &&
          <div className={'w-1/2 pt-10 mx-auto'}>
            {beneficiaryRechargingFileTestResult.rows.map((result: RowTestResult<CsvBeneficiaryRecharging>, key: number) => {
              if (result.rowIndex === -1) {
                return (
                  <div className={'flex flex-col border border-solid border-status-error rounded-br10 p-2'}>
                    <div className={'flex gap-2'}>
                      <WarningSvg width={20} />
                      <p className={'font-medium'}>
                        {t('schemaError.title')}
                      </p>
                    </div>
                  </div>
                );
              }

              const numberOfLine: number = result.rowIndex + 2;
              return (result.errors.length
                  ? <div key={key} className={'flex flex-col rounded-br10 p-2'}>
                    <div className={'flex gap-2'}>
                      <WarningSvg width={20} />
                      <p className={'font-medium'}>
                        {t('uploadFields.titleField', {
                          count: result.errors.length,
                          lineNumber: numberOfLine,
                        })}
                      </p>
                    </div>
                    {result.errors.map((_error: FieldTestReport<CsvBeneficiaryRecharging>, key2: number) => (
                      <span key={key2} className={'text-sm pl-16 pb-1'}>
                          {t(
                            `uploadErrors.${_error.errorMessage}`,
                            {
                              field: beneficiaryRechargingKeysMap[_error.fieldName],
                              minValue: _error.minValue,
                              maxValue: _error.maxValue,
                              minLength: _error.minLength,
                              maxLength: _error.maxLength,
                              lowerRange: _error.lowerRange,
                              upperRange: _error.upperRange,
                              expectedValue: _error.expectedValue,
                            },
                          )}
                      </span>
                    ))}
                  </div>
                  : <div key={key}></div>
              );
            })}
          </div>
      }

      <Toaster open={addNewBeneficiariesUploadViewStore.showErrorDialog} variant={'error'}>
        <>
          <Button onClick={() => addNewBeneficiariesUploadViewStore.setShowErrorDialog(false)} variant="text">
            <CloseSvg className={'w-4 h-4 text-primary'} title={'icon de fermeture'}/>
          </Button>
          <p className={'p-1'}>{t('snackError')}</p>
        </>
      </Toaster>

      {hasError &&
          <Toaster open={hasError} variant={'error'}>
            {errorMessage
              ? t('errorNaming', { undefinedKey })
              : t('snackError')}
          </Toaster>}

      <RechargingOrderExistDialog
        isActive={alreadyHasOrderForSelectedMonth}
        onClose={() => setAlreadyHasOrderForSelectedMonth(false)}
        takeNewOrder={() => stepperRechargingStore.goToNextStep()}
        orderMonth={rechargingViewStore.selectedReloadingDate}
      />
    </>
  );
}));

export default AddNewBeneficiariesUpload;
